
const initAccordion = (isExpanded: Ref<boolean>, el: HTMLElement, id: number) => {
  const link = el.querySelector(".js-accordion-link");
  const content = el.querySelector(".js-accordion-content");

  const isReferencesAccordion = (el.parentNode as HTMLElement)?.classList.contains("js-references-accordion");

  if (!link || !content) {
    return false;
  }

  const contentWrapper = wrap(content as HTMLElement, document.createElement("div"));

  contentWrapper.classList.add("accordion-wrapper");

  const editorView = document.querySelector("body.is-page-editor");

  let expanded = false;
  let contentBuffer = setTimeout(() => { }, 1);

  const animationSpeed = 400;

  if (el.classList.contains("is-open") || editorView) {
    expanded = true;
    toggleContent(expanded);
  } else {
    setHeight(contentWrapper, "0px");
  }

  toggleCollapsibleIcon(expanded);

  link.setAttribute("aria-expanded", expanded.toString());
  link.setAttribute("id", `accordion-link-${id}`);
  link.setAttribute("aria-controls", `accordion-content-${id}`);

  contentWrapper.setAttribute("id", `accordion-content-${id}`);
  contentWrapper.setAttribute("aria-labelledby", `accordion-link-${id}`);

  if (!editorView) {
    link.addEventListener("click", (e) => {
      e.preventDefault();
      toggleContent(!expanded);
      toggleCollapsibleIcon(!expanded);
    });
  }

  if (isReferencesAccordion) {
    const referenceElements = document.querySelectorAll(".reference");
    referenceElements.forEach((el, index) => {
      el.addEventListener("click", (e) => {
        toggleContent(true);
        toggleCollapsibleIcon(true);
      });
    });
  }

  watch(isExpanded, (value) => toggleContent(value));

  function toggleContent(openState: boolean) {
    if (openState === expanded) {
      return;
    }
    const height = content?.scrollHeight || 0;
    expanded = openState;

    link && link.setAttribute("aria-expanded", openState.toString());

    clearTimeout(contentBuffer);

    if (openState) {
      el.classList.add("is-open");
      contentWrapper.style.visibility = "visible";
      setHeight(contentWrapper, `${height}px`);
      contentBuffer = setTimeout(() => {
        setHeight(contentWrapper, "auto");
        contentWrapper.style.overflow = "visible";
      }, animationSpeed + 20);
    } else {
      el.classList.remove("is-open");
      setHeight(contentWrapper, `${height}px`);

      setTimeout(() => {
        setHeight(contentWrapper, "0");
      }, 20);

      setTimeout(() => {
        contentWrapper.style.visibility = "hidden";
      }, animationSpeed + 20);
    }
  }

  function toggleCollapsibleIcon(openState: boolean) {
    const collapseIcon = el.querySelector(".collapse-icon") as HTMLElement;
    const expandIcon = el.querySelector(".expand-icon") as HTMLElement;

    if (collapseIcon) {
      collapseIcon.style.display = expanded ? 'flex' : 'none';
    }

    if (expandIcon) {
      expandIcon.style.display = expanded ? 'none' : 'flex'
    }
  }

  function setHeight(el: HTMLElement, height: string) {
    el.setAttribute(
      "style",
      `height: ${height}; transition: height ${animationSpeed}ms ease; overflow: hidden;`
    );
  }

  function wrap(el: HTMLElement, wrapper: HTMLElement) {
    el.parentNode?.insertBefore(wrapper, el);
    wrapper.appendChild(el);
    return wrapper;
  }
};

export const useAccordion = (elementRef: Ref<HTMLElement | null>, id?: number) => {

  const expanded = ref(false);
  onMounted(() => {
    if (!elementRef?.value) return;
    initAccordion(expanded, elementRef.value! as HTMLElement, id ?? 0);
  })
  return { expanded }
}
