<template>
  <SmartLinkItemComponent
    v-if="model"
    :model="model"
    :component="!model.system.workflow"
    :id="model.system.id"
  >
    <SmartLinkStandaloneElement :codename="type.elements.title.codename">
      <div ref="accordion" :class="`js-accordion accordion__item ${isOpen ? 'is-open' : ''}`">
        <button class="js-accordion-link accordion__title title" type="button">
          <div>{{ data.title }}</div>
          <div class="accordion__icon-wrapper">
            <IconCollapseCircle class="accordion__icon collapse-icon" />
            <IconExpandCircle class="accordion__icon expand-icon" />
          </div>
        </button>
        <div class="js-accordion-content">
          <RichText v-if="isMounted" :value="data.content" />
        </div>
      </div>
    </SmartLinkStandaloneElement>
  </SmartLinkItemComponent>
</template>

<script lang="ts" setup>
import type { AccordionItemModel } from "~/models";
import { contentTypes } from "~/models";

const type = contentTypes._accordion_item;
const props = defineProps<{
  model: AccordionItemModel,
  index: number,
  isOpen: boolean,
}>();

const data = {
  title: props.model.elements.title.value,
  content: props.model.elements.content.value,
};

const accordion = ref<HTMLElement | null>(null);
useAccordion(accordion, props.index);
provide("ctaOptions", {
  values: {
    contextClass: "",
    buttonWrapperType: "nonstandard",
  },
});

const isMounted = ref(false);

onMounted(() => isMounted.value = true)
</script>
<style lang="scss">
@import "~/assets/scss/abstracts/_variables.scss";

.js-accordion-content {
  margin-bottom: 16px;

  h2 {
    font-size: 20px;
    color: $black-blue-02;
  }

  h3 {
    color: $deep-blue-01;
    font-family: $ff-lato;
    font-size: 24px;
    font-weight: normal;
    line-height: 1.2;
    text-transform: none;
  }
}
</style>
